<template>
  <div class="input_container" @click="showModal">
    <a-tooltip v-if="val">
      <template #title>{{ val }}</template>
      <p class="ellipsis">{{ val }}</p>
    </a-tooltip>
    <p v-else class="placeholder">{{ placeholder }}</p>
  </div>
  <a-modal
    v-model:visible="visible"
    :title="title"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="包含" name="contain">
        <tagInput
          v-model:value="formState.contain"
          :title="title"
          :input-tag="inputTag"
          :options="options"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 9 }" name="relation">
        <a-radio-group v-model:value="formState.relation">
          <a-radio value="and">与</a-radio>
          <a-radio value="or">或</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="不包含" name="notContain">
        <tagInput
          v-model:value="formState.notContain"
          :title="title"
          :input-tag="inputTag"
          :options="options"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive, computed, defineProps, defineEmits, watch } from "vue";
import TagInput from "./tagInput.vue";
import { Form } from "ant-design-vue";

const val = ref("");
const placeholder = computed(() => {
  return "请输入" + props.title;
});

const labelCol = { span: 3 };
const wrapperCol = { span: 21 };
const formState = reactive({
  contain: {
    values: [],
    relation: "or",
  },
  notContain: {
    values: [],
    relation: "or",
  },
  relation: "and",
});

const props = defineProps({
  value: {
    isRequired: true,
    default() {
      return {
        contain: {
          values: [],
          relation: "or",
        },
        relation: "and",
        notContain: {
          values: [],
          relation: "or",
        },
      };
    },
  },
  title: {
    type: String,
    default: "标题",
  },
  inputTag: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Array,
    default() {
      return [
        {
          label: "",
          value: "",
        },
      ];
    },
  },
});
watch(
  () => props.value,
  (newVal) => {
    console.log(newVal);
    formState.contain = newVal.contain;
    formState.notContain = newVal.notContain;
    formState.relation = newVal.relation;
    if (!newVal.contain.values.length && !newVal.notContain.values.length) {
      val.value = "";
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.options,
  (newVal) => {
    console.log(newVal);
    resetFields();
  }
);

const emit = defineEmits(["update:value"]);

const formItemContext = Form.useInjectFormItemContext();
const triggerChange = () => {
  console.log(formState);
  emit("update:value", formState);
  formItemContext.onFieldChange();
};

let oldFormState = {
  contain: {
    values: [],
    relation: "or",
  },
  relation: "and",
  notContain: {
    values: [],
    relation: "or",
  },
};
const visible = ref(false);
const showModal = () => {
  visible.value = true;
  // 记录旧数据
  oldFormState = JSON.parse(JSON.stringify(formState));
  console.log(oldFormState);
};

const resetFields = () => {
  val.value = "";
  formState.contain = {
    values: [],
    relation: "or",
  };
  formState.notContain = {
    values: [],
    relation: "or",
  };
  formState.relation = "and";
};

const relationToZh = (relation) => {
  return relation === "or" ? "或" : "与";
};

const handleOk = () => {
  // val的显示
  // <包含：除皱或氨基酸>或<不包含：玻尿酸>
  setTimeout(() => {
    const containRelation = relationToZh(formState.contain.relation);
    const contain = formState.contain.values.join(containRelation);
    const relation = formState.relation === "or" ? "或" : "且";
    const notContainRelation = relationToZh(formState.notContain.relation);
    const notContain = formState.notContain.values.join(notContainRelation);
    if (contain === "" && notContain === "") {
      resetFields();
    } else if (contain === "") {
      val.value = `<不包含：${notContain}>`;
    } else if (notContain === "") {
      val.value = `<包含：${contain}>`;
    } else {
      val.value = `<包含：${contain}>${relation}<不包含：${notContain}>`;
    }
    triggerChange();
    visible.value = false;
  }, 150);
};
const handleCancel = () => {
  if (!val.value) {
    resetFields();
  } else {
    // 还原原始值
    console.log(oldFormState);
    formState.contain = oldFormState.contain;
    formState.notContain = oldFormState.notContain;
    formState.relation = "and";
  }
};
</script>

<style scoped lang="scss">
.input_container {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  transition: all 0.3s;
  width: 80%;
  color: rgba(0, 0, 0, 0.85);
  p {
    margin: 0;
  }

  .placeholder {
    color: #bfbfbf;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    cursor: pointer;
  }
}
</style>
