<template>
  <div class="tag_input_container">
    <a-select
      v-if="inputTag"
      v-model:value="tagValue.values"
      mode="tags"
      :max-tag-count="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      :show-arrow="false"
      :placeholder="placeholder"
      :open="false"
      @change="onTagChange"
    ></a-select>
    <a-select
      v-else
      v-model:value="tagValue.values"
      mode="multiple"
      :max-tag-count="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      show-arrow
      option-filter-prop="label"
      :placeholder="placeholder"
      :options="options"
      @change="onTagChange"
    >
    </a-select>
    <a-select
      v-model:value="tagValue.relation"
      class="relation_select"
      :options="relationOptions"
      @change="onRelationChange"
    ></a-select>
  </div>
</template>

<script setup>
import { reactive, watch, computed, defineProps, defineEmits } from "vue";
import { Form } from "ant-design-vue";

let tagValue = reactive({
  values: [],
  relation: "and",
});

const maxTagCount = 4;
const maxTagTextLength = 9;

const props = defineProps({
  value: {
    isRequired: true,
    default() {
      return {
        values: [],
        relation: "and",
      };
    },
  },
  title: {
    type: String,
    default: "产品",
  },
  inputTag: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Array,
    default() {
      return [
        {
          label: "",
          value: "",
        },
      ];
    },
  },
});
watch(
  () => props.value,
  (val) => {
    console.log(val);
    tagValue.values = val.values;
    tagValue.relation = val.relation;
  },
  { deep: true, immediate: true }
);

const placeholder = computed(() => {
  return "请输入" + props.title;
});

const emit = defineEmits(["update:value"]);

const formItemContext = Form.useInjectFormItemContext();
const triggerChange = () => {
  emit("update:value", tagValue);
  formItemContext.onFieldChange();
};

const relationOptions = [
  {
    value: "and",
    label: "与",
  },
  {
    value: "or",
    label: "或",
  },
];
const onTagChange = (val) => {
  console.log(val);
  triggerChange();
};
const onRelationChange = (val) => {
  console.log(val);
  triggerChange();
};
</script>

<style scoped lang="scss">
.tag_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.relation_select {
  margin-left: 10px;
  width: 80px;
}
</style>
