<template>
  <div class="toolWrap">
    <el-card class="box-card" style="margin-bottom: 20px">
      <template #header>
        <div class="card-header">
          <span class="mainTitle">筛选</span>
        </div>
      </template>
      <div>
        <el-row style="margin-bottom: 30px">
          <el-col :span="12">
            <!-- <span style="margin-left: 10px; margin-right: 20px">时间范围*</span> -->
            <a-form-item label="时间范围*">
              <el-radio v-model="timeType" label="month" size="small"
                >月</el-radio
              >
              <el-radio v-model="timeType" label="day" size="small"
                >日</el-radio
              >
              <el-date-picker
                size="small"
                v-model="timeRange"
                :type="type"
                unlink-panels
                range-separator="To"
                :start-placeholder="startPlaceholder"
                :end-placeholder="endPlaceholder"
                :shortcuts="shortcuts"
              />
            </a-form-item>
          </el-col>
          <el-col :span="12">
            <a-form-item label="品牌">
              <el-select
                size="small"
                v-model="selBrand"
                multiple
                filterable
                collapse-tags
                collapse-tags-tooltip
                placeholder="请选择"
                style="width: 240px"
              >
                <el-option
                  v-for="item in chooseBrandList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </a-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <a-form-item
              label="发文内容"
              name="title"
              style="margin-bottom: 10px; margin-right: 20px"
            >
              <relationShipInput v-model:value="title" title="内容搜索" />
            </a-form-item>
          </el-col>
          <el-col :span="12">
            <a-form-item label="平台">
              <el-radio-group v-model="platform" size="small">
                <el-radio-button label="all">全平台</el-radio-button>
                <el-radio-button label="wechat">微信</el-radio-button>
                <el-radio-button label="weibo">微博</el-radio-button>
                <el-radio-button label="douyin">抖音</el-radio-button>
                <el-radio-button label="xiaohongshu">小红书</el-radio-button>
                <el-radio-button label="bilibili">哔哩哔哩</el-radio-button>
              </el-radio-group>
            </a-form-item>
            <!-- <span style="margin-left: 10px; margin-right: 20px">平台</span> -->
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span class="mainTitle">下载</span>
        </div>
      </template>
      <div>
        <div class="downloadBox">
          <span style="margin-right: 30px">全量发帖数据下载</span>
          <el-button
            class="btn"
            :loading="btnLoading1"
            @click="downloadData('all')"
            size="mini"
            :disabled="disabled1"
            type="primary"
            >下载</el-button
          >
        </div>
        <div class="downloadBox">
          <span style="margin-right: 30px">品牌人群画像下载</span>
          <el-button
            class="btn"
            :loading="btnLoading2"
            @click="downloadData('brand')"
            size="mini"
            :disabled="disabled2"
            type="primary"
            >下载</el-button
          >
        </div>
        <div class="downloadBox">
          <span style="margin-right: 30px">品牌KOL投放结构下载</span>
          <el-button
            class="btn"
            :loading="btnLoading3"
            @click="downloadData('kol')"
            size="mini"
            :disabled="disabled3"
            type="primary"
            >下载</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getBrand } from "@/api/sov/sovBrand";
import relationShipInput from "./components/relationShipInput";
import { setStartDate, setEndDate } from "./tools";
import {
  downloadSovArticle,
  downloadSovPersona,
  downloadSovKol,
} from "@/api/tool";
export default {
  data() {
    return {
      brand: "",
      chooseBrandList: [],
      timeType: "month",
      shortcuts: [
        // {
        //   text: "Last week",
        //   value: () => {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        //     return [start, end];
        //   },
        // },
        {
          text: "近1个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 1);
            return [start, end];
          },
        },
        {
          text: "近3个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "近6个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
      ],
      selBrand: "",
      type: "daterange",
      startPlaceholder: "开始月份",
      endPlaceholder: "结束月份",
      title: {
        contain: {
          values: [],
          relation: "or",
        },
        relation: "and",
        notContain: {
          values: [],
          relation: "or",
        },
      },
      platform: "all",
      timeRange: "",
      btnLoading1: false,
      btnLoading2: false,
      btnLoading3: false,
      disabled1: false,
      disabled2: false,
      disabled3: false,
    };
  },
  methods: {
    async getBrand() {
      let res = await getBrand();
      if (res.code === 0) {
        this.chooseBrandList = this.formatBrandList(res.data);
      } else if (res.code !== 123) {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      console.log(res);
    },
    formatBrandList(data) {
      let arr = [];
      data.forEach((v) => {
        arr.push({
          label: v.brand,
          value: v.brandId,
        });
      });
      return arr;
    },
    downloadData(type) {
      if (this.timeRange === "") {
        this.$message({
          type: "info",
          message: "请输入时间范围！",
        });
        return;
      }
      if (type === "all") {
        this.btnLoading1 = true;
        this.disabled2 = true;
        this.disabled3 = true;
        downloadSovArticle(this.params, "全量发帖数据.xlsx")
          .then(() => {
            this.$message({
              type: "success",
              message: "文件正在下载",
            });
            this.btnLoading1 = false;
            this.disabled2 = false;
            this.disabled3 = false;
          })
          .catch(() => {
            this.$message.error("文件下载失败,请联系管理员!");
          });
      } else if (type === "brand") {
        this.btnLoading2 = true;
        this.disabled1 = true;
        this.disabled3 = true;
        downloadSovPersona(this.params, "品牌人群画像.xlsx")
          .then(() => {
            this.$message({
              type: "success",
              message: "文件正在下载",
            });
            this.btnLoading2 = false;
            this.disabled1 = false;
            this.disabled3 = false;
          })
          .catch(() => {
            this.$message.error("文件下载失败,请联系管理员!");
          });
      } else {
        this.btnLoading3 = true;
        this.disabled1 = true;
        this.disabled2 = true;
        downloadSovKol(this.params, "品牌KOL投放结构.xlsx")
          .then(() => {
            this.$message({
              type: "success",
              message: "文件正在下载",
            });
            this.btnLoading3 = false;
            this.disabled1 = false;
            this.disabled2 = false;
          })
          .catch(() => {
            this.$message.error("文件下载失败,请联系管理员!");
          });
      }
    },
  },
  mounted() {
    this.getBrand();
  },
  computed: {
    params() {
      return {
        include: this.title.contain.values,
        includeRelation: this.title.contain.relation,
        exclude: this.title.notContain.values,
        excludeRelation: this.title.notContain.relation,
        relation: this.title.relation,
        brands: this.selBrand,
        platform: this.platform === "all" ? null : this.platform,
        startDate: `${setStartDate(this.timeRange[0])} 00:00:00`,
        endDate: `${setEndDate(this.timeRange[1])} 23:59:59`,
      };
    },
    isDowning() {
      if (this.btnLoading1 || this.btnLoading2 || this.btnLoading3) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    timeType: {
      handler(val) {
        if (val === "month") {
          this.type = "monthrange";
          this.startPlaceholder = "开始月份";
          this.endPlaceholder = "结束月份";
        } else {
          this.type = "daterange";
          this.startPlaceholder = "开始日期";
          this.endPlaceholder = "结束日期";
        }
      },
      immediate: true,
    },
  },
  components: {
    relationShipInput,
  },
};
</script>

<style lang="scss">
.el-picker-panel__sidebar {
  width: 124px !important;
}
.toolWrap {
  .el-date-editor .el-range-separator {
    width: 8%;
  }
  .el-radio-button__original-radio:checked + .el-radio-button__inner,
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-color: transparent;
    box-shadow: -1px 0 0 0 transparent;
  }

  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }
  .el-radio__input.is-checked .el-radio__inner {
    color: #e09a29 !important;
    border-color: #e09a29 !important;
    background: linear-gradient(180deg, #daae3c, #e09524);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #e09a29 !important;
  }
  .el-radio__inner:hover {
    border-color: #e09a29;
  }
}
</style>

<style lang="scss" scoped>
.mainTitle {
  font-size: 18px;
  font-weight: 800;
}
.box-card {
  border-radius: 5px;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
.downloadBox {
  margin-bottom: 12px;
  height: 30px;
  line-height: 30px;
  width: 400px;
  vertical-align: middle;
  .btn {
    float: right;
    background-color: #4d7ad7;
  }
}
.btnPublic {
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  color: #fff;
}
.btnDown {
  margin-left: 30px;
  padding: 6px 8px;

  .downIconClass {
    margin-left: 10px;
    font-size: 16px;
  }
}
</style>
